import CodeMirror from 'codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/ruby/ruby';
import 'codemirror/addon/edit/closebrackets';
import 'codemirror/addon/edit/matchbrackets';
import 'codemirror/addon/search/match-highlighter';
import 'codemirror/addon/selection/active-line';
import 'codemirror/theme/dracula.css';

$(document).ready(() => {
  const initializeCodeMirror = () => {
    const textarea = document.getElementById('agent_custom_code');
    if (textarea) {
      CodeMirror.fromTextArea(textarea, {
        lineNumbers: true,
        mode: 'ruby',
        theme: 'dracula',
        autoCloseBrackets: true,
        matchBrackets: true,
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        styleActiveLine: true
      });
    }
  };

  initializeCodeMirror();

  $('#agent_type').on('change', (e) => {
    if (e.currentTarget.value == 'Agents::CustomAgent') {
      setTimeout(initializeCodeMirror, 500);
    }
  });
});
